<template>
  <div v-if="!loading">
    <step-editor
      :step="step"
      :editable="canEdit"
      @deleted="() => $router.go(-1)"
      @onUpdate="getStep"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { adminRole, teacherRole, userRoles } from '@/store/user/index'
import StepEditor from '@/components/new_courses/course/theme/lesson/step/StepEditor.vue'

export default {
  name: 'Step',
  components: {
    StepEditor,
  },
  data: () => ({
    step: {
      stepElements: [],
    },
    loading: true,
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
    }),
    stepId() {
      return this.$route.params.step_id
    },
    userRole() {
      return this.user ? userRoles[this.user.role] : ''
    },
    isTeacher() {
      return this.userRole === teacherRole
    },
    isAdmin() {
      return this.userRole === adminRole
    },
    canEdit() {
      if (!this.step) return true
      return this.step.user_id === this.user.id || this.isAdmin
    },
    name() {
      return this.step.name || 'Без названия'
    },
  },
  watch: {
    stepId() {
      if (this.stepId) this.getStep()
    },
  },
  async mounted() {
    if (this.stepId) await this.getStep()
    this.loading = false
  },
  methods: {
    async getStep() {
      this.loading = true
      this.$eventBus.$emit('page-title', 'Загрузка...')
      this.step = await this.$http.get(`/lesson-steps/view?id=${this.stepId}&expand=user,stepElements.image,lessonStepsTags.tags`)
      this.$eventBus.$emit('page-title', this.name)
      this.loading = false
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/pages/page-knowledge-base.scss';
</style>
